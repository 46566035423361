import React from "react";
import { Grid, Link } from "@material-ui/core";
import Loja1 from "../../assets/i/lojas/frame-01.jpg";
import Loja2 from "../../assets/i/lojas/frame-02.jpg";
import Loja3 from "../../assets/i/lojas/frame-03.jpg";
import Loja4 from "../../assets/i/lojas/frame-04.jpg";
import Loja5 from "../../assets/i/lojas/frame-05.jpg";
import Loja6 from "../../assets/i/lojas/frame-06.jpg";

const info = [
  {
    title: "Nossas lojas",
    img: <img className="img" alt="1 Acesse" src={Loja1} />,
    link: "https://www.petz.com.br/nossas-lojas",
  },
  {
    title: "Banho e Tosa",
    img: <img className="img" alt="1 Acesse" src={Loja2} />,
    link: "https://landingpage.petz.com.br/banho-e-tosa",
  },
  {
    title: "Cansei de Ser e Gato",
    img: <img className="img" alt="1 Acesse" src={Loja3} />,
    link: "https://www.petz.com.br/cansei-de-ser-gato",
  },
  {
    title: "Blog da Petz",
    img: <img className="img" alt="1 Acesse" src={Loja4} />,
    link: "https://www.petz.com.br/blog/",
  },
  {
    title: "Cão Cidadão",
    img: <img className="img" alt="1 Acesse" src={Loja5} />,
    link: "https://landingpage.petz.com.br/adestramento/?utm_source=site-petz&utm_medium=footer-servicos&utm_campaign=footer-servicos",
  },
  {
    title: "Zee.Dog",
    img: <img className="img" alt="1 Acesse" src={Loja6} />,
    link: "https://www.petz.com.br/zee-dog",
  },
];

const Products = () => {
  return (
    <>
      <Grid className="products-view">
        <h2>Confira nossos serviços e produtos</h2>
        <Grid className="products-container">
          {info.map(({ title, img, link }) => {
            return (
              <Grid direction="column" className="products-content">
                <Link target="_blank" href={link}>
                  <Grid className="frame">
                    <span>{img}</span>
                  </Grid>
                </Link>
                <h2> {title}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default Products;

