import React from "react";
import { Grid, Link } from "@material-ui/core";

const wantedInfo = [
  {
    links: [
      <Link target="_blank" href="https://www.petz.com.br/">
        Pet Shop
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/advocate">
        Advocate
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/gato/racao/f/royal-canin"
      >
        Royal Canin Gatos
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/golden">
        Ração Golden
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/glicopan">
        Glicopan Pet
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/cachorro/casas-e-tocas/casas"
      >
        Casinha de Cachorro
      </Link>,
    ],
  },
  {
    links: [
      <Link
        target="_blank"
        href="https://www.petz.com.br/cachorro/racao/racao-seca"
      >
        Ração para Cachorro
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/gato/arranhadores-e-brinquedos/arranhadores"
      >
        Arranhador para Gatos
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/peixes/aquarios-e-beteiras"
      >
        Aquários
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/cachorro/camas-e-cobertores"
      >
        Cama para Cachorro
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/frontline">
        Frontline
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/royal-canin">
        Royal Canin
      </Link>,
    ],
  },
  {
    links: [
      <Link target="_blank" href="https://www.petz.com.br/bayerpet">
        Bayer Pet
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/pedigree">
        Pedigree
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/premierpet">
        Ração Premier
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/baytril">
        Baytril
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/advantage">
        Advantage
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/seresto">
        Seresto
      </Link>,
    ],
  },
  {
    links: [
      <Link target="_blank" href="https://www.petz.com.br/frontline-plus">
        Frontline Plus
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/nexgard">
        Nexgard
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/cachorro/coleiras-guias-e-peitorais"
      >
        Coleira
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/whiskas">
        Whiskas
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/capstar">
        Capstar
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/outros-pets/hamster-e-twister"
      >
        Hamster
      </Link>,
    ],
  },
  {
    links: [
      <Link
        target="_blank"
        href="https://www.petz.com.br/outros-pets/hamster-e-twister/gaiolas-e-casinhas"
      >
        Gaiola
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/gato/arranhadores-e-brinquedos"
      >
        Brinquedos para gatos
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/cachorro/beleza-e-limpeza/maquina-de-tosa-e-acessorios"
      >
        Máquina de Tosa para Cachorros
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/drontal">
        Drontal
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/cachorro/portoes-grades-e-escadas"
      >
        Portão para Cachorro
      </Link>,
    ],
  },
  {
    links: [
      <Link target="_blank" href="https://www.petz.com.br/hills">
        Ração Hills
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/royal-canin/f/idade_filhote/royal-canin"
      >
        Royal Canin Filhotes
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/nestle-purina-proplan"
      >
        Proplan
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/gato/arranhadores-e-brinquedos/brinquedos-com-erva-do-gato"
      >
        Erva do Gato
      </Link>,
    ],
  },
];

const gloassaryInfo = [
  {
    links: [
      <Link target="_blank" href="https://www.petz.com.br/marcas/a">
        A
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/b">
        B
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/c">
        C
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/d">
        D
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/e">
        E
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/f">
        F
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/g">
        G
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/h">
        H
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/i">
        I
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/j">
        J
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/k">
        K
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/l">
        L
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/m">
        M
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/n">
        N
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/o">
        O
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/p">
        P
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/q">
        Q
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/r">
        R
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/s">
        S
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/t">
        T
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/u">
        U
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/v">
        V
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/w">
        W
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/x">
        X
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/y">
        Y
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/marcas/z">
        Z
      </Link>,
    ],
  },
];

const Glossary = () => {
  return (
    <Grid style={{ padding: "2rem", background: "#f5f5f5" }}>
      <Grid className="glossary-view">
        <Grid>
          <h1 className="title">Mais buscados</h1>
          <Grid style={{ display: "flex", gap: "5%" }}>
            {wantedInfo.map(({ links }) => {
              return (
                <Grid
                  style={{ display: "flex", flex: "25% 1" }}
                  direction="column"
                  className="glossary-content"
                >
                  {links.map((item) => {
                    return <p> {item} </p>;
                  })}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid className="glossary-letters">
          <Grid style={{ display: "flex", gap: "1rem" }}>
            <h1 className="title">Glossário de Marcas:</h1>
            <Grid style={{ display: "flex", gap: "5%" }}>
              {gloassaryInfo.map(({ links }) => {
                return (
                  <Grid
                    style={{ display: "flex", gap: "1rem" }}
                    className="glossary-content"
                  >
                    {links.map((item) => {
                      return <p> {item} </p>;
                    })}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Glossary;

