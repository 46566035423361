import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "@material-ui/core";
import "../Modal.scss";

const ModalGeneric = ({
  title,
  showModal,
  text,
  handleClose,
  buttonClick,
  buttonText,
  buttonType,
  linkHref,
  linkText,
  linkClick,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={buttonType === "secondary" && "button-cancel"}
          variant={buttonType && "secondary"}
          onClick={() => {
            buttonClick();
          }}
        >
          {buttonText}
        </Button>
        {linkClick && (
          <Link
            href={linkHref}
            onClick={() => {
              linkClick();
            }}
          >
            <Button>{linkText}</Button>
          </Link>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGeneric;

