import React from "react";

import { Grid, useMediaQuery } from "@material-ui/core";
import Politics from "./Politics";
import HelpCard from "./HelpCard";
import Products from "./Products";
import DownloadCard from "./DownloadCard";
import SiteMap from "./SiteMap";
import Glossary from "./Glossary";
import SocialMedia from "./SocialMedia";
import "./Footer.scss";

const Rodape = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isAtLeastSmallScreen = useMediaQuery("(min-width: 576px)");

  return (
    <Grid>
      <Politics />
      <HelpCard />

      {isDesktop && <Products />}

      {isAtLeastSmallScreen && <DownloadCard />}
      {isAtLeastSmallScreen && <SiteMap />}
      <SocialMedia />
      {isDesktop && <Glossary />}

      {isDesktop && (
        <Grid
          className="footer-text"
          style={{ display: "flex", background: "#f5f5f5" }}
          direction="column"
          alignItems="center"
        >
          <h2>
            Para valores diferentes, o preço final sempre será o da sacola de
            compras.
          </h2>

          <p>
            Pet Center Comércio Participações S/A - CNPJ 18.328.118/0013-42 -
            Endereço: Rua Dr. Miguel Paulo Capalbo, 135 - Pari - São Paulo - SP
            - CEP: 03035-040
          </p>

          <p>
            Copyright© 2024 Pet Center Comércio e Participações S/A - Todos os
            direitos reservados
          </p>
        </Grid>
      )}
    </Grid>
  );
};

export default Rodape;

