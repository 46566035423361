import React from "react";
import { Grid, Link } from "@material-ui/core";
import Logo from "../../assets/i/logo-white.svg";
import QrCode from "../../assets/i/qr-code.jpg";
import AppStore from "../../assets/i/app-store.svg";
import GoogleStore from "../../assets/i/google-store.svg";

const DownloadCard = () => {
  return (
    <Grid style={{ padding: "2rem" }}>
      <Grid
        style={{ display: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        className="download-view"
      >
        <Grid>
          <img
            className="img"
            alt="1 Acesse"
            src={Logo}
            style={{ height: "30px" }}
          />
          <p>Baixe o nosso app e tenha acesso a tudo que o seu pet precisa </p>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid className="qrcode">
            <img className="img" alt="1 Acesse" src={QrCode} />
          </Grid>
          <Grid
            style={{ display: "flex", gap: "1rem", padding: "2rem 0" }}
            direction="column"
          >
            <Link
              target="_blank"
              href="https://apps.apple.com/br/app/petz-tudo-que-seu-pet-precisa/id1250491446"
            >
              <img className="img" alt="1 Acesse" src={AppStore} />
            </Link>
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=br.com.petz&pli=1"
            >
              <img className="img" alt="1 Acesse" src={GoogleStore} />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DownloadCard;

