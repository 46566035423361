import React from "react";
import { Grid, Link } from "@material-ui/core";

const siteMapInfo = [
  {
    title: "Sobre",
    links: [
      <Link
        target="_blank"
        href="https://www.petz.com.br/institucional/sobre-a-petz"
      >
        Quem somos
      </Link>,
      <Link target="_blank" href="https://petz.gupy.io/">
        Trabalhe com a gente
      </Link>,
      <Link
        target="_blank"
        href="https://hcm19.sapsf.com/sf/jam?bplte_company=petcenterc#/login"
      >
        Portal Petz
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/institucional/expansao"
      >
        Expansão
      </Link>,
      <Link target="_blank" href="https://ri.petz.com.br/">
        Relação com Investidores
      </Link>,
    ],
  },
  {
    title: "Acesse",
    links: [
      <Link
        target="_blank"
        href="https://www.petz.com.br/checkout/login/meu-cadastro"
      >
        Meu cadastro
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/checkout/login/petz-pedidos"
      >
        Meus pedidos
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/checkout/login/minhas-assinaturas"
      >
        Minhas assinaturas
      </Link>,
    ],
  },
  {
    title: "Pra você",
    links: [
      <Link
        target="_blank"
        href="https://landingpage.petz.com.br/assinatura-petz/"
      >
        Assinatura Petz
      </Link>,
      <Link target="_blank" href="#">
        Vale a Pena Ser Fiel
      </Link>,
      <Link
        target="_blank"
        href="https://www.petz.com.br/institucional/pesquise-la-compre-aqui"
      >
        Pesquise Lá, Compre Aqui
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/petcommerce">
        Pet-commerce
      </Link>,
      <Link target="_blank" href="#">
        Promoções
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/blog/">
        Blog Petz
      </Link>,
    ],
  },
  {
    title: "Serviços",
    links: [
      <Link target="_blank" href="https://landingpage.petz.com.br/banho-e-tosa/">
        Banho e Tosa
      </Link>,
      <Link target="_blank" href="#">
        Centro Veterinário Seres
      </Link>,
      <Link target="_blank" href="#">
        Adote Petz
      </Link>,
      <Link target="_blank" href="#">
        Parceiro Petz
      </Link>,
    ],
  },
  {
    title: "Precisa de ajuda?",
    links: [
      <Link target="_blank" href="https://www.petz.com.br/institucional/ouvidoria">
        Fale com a gente
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/institucional/trocas-e-devolucoes">
        Trocas e devoluções
      </Link>,
      <Link target="_blank" href="https://canalconfidencial.com.br/petz/#home">
        Canal de denúncias
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/institucional/como-comprar">
        Como comprar
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/institucional/ouvidoria">
        Ouvidoria
      </Link>,
    ],
  },
  {
    title: "Políticas",
    links: [
      <Link target="_blank" href="https://www.petz.com.br/institucional/politicafretegratis">
        Entrega
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/institucional/politicafretegratis">
        Frete grátis
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/institucional/pagamentos-e-reembolsos">
        Pagamentos e reembolsos
      </Link>,
      <Link target="_blank" href="https://www.petz.com.br/institucional/politica-de-privacidade">
        Privacidade
      </Link>,
    ],
  },
];

const SiteMap = () => {
  return (
    <Grid style={{ padding: "2rem" }}>
      <Grid className="site-map-view">
        <Grid style={{ display: "flex", gap: "5%" }}>
          {siteMapInfo.map(({ title, links }) => {
            return (
              <Grid
                style={{ display: "flex", flex: "25% 1" }}
                direction="column"
                className="site-map-content"
              >
                <h2> {title}</h2>
                {links.map((item) => {
                  return <p> {item} </p>;
                })}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SiteMap;

