import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter basename={process.env.PUBLIC_URL || ""}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
