import React, {
  useEffect,
  useState,
  useMemo,
  Suspense,
  createContext,
} from "react";
import { ToastContainer } from "react-toastify";

import "./App.scss";
import AppRoutes from "./configurations/routes";
import Layout from "./components/layout/BaseLayout";

import "react-toastify/dist/ReactToastify.min.css";

export const UserContext = createContext({
  loggedIn: false,
  setLoggedIn: () => {},
});

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const loggedInMemo = useMemo(() => ({ loggedIn, setLoggedIn }), [loggedIn]);

  useEffect(() => {
    setLoggedIn(localStorage.getItem("user_auth") !== null);
    console.log(`Data Inicio: ${process.env["REACT_APP_FIRST_DRAW_STARTS"]}`);
  }, []);

  return (
    <>
      <UserContext.Provider value={loggedInMemo}>
        <Layout>
          <Suspense>
            <AppRoutes />
          </Suspense>
        </Layout>
      </UserContext.Provider>
      {<ToastContainer theme="colored" />}
    </>
  );
};

export default App;

