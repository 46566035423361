import React from "react";
import { Grid, Container, Button, Link } from "@material-ui/core";

const HelpCard = () => {
  return (
    <Container style={{ padding: "2rem 24px" }}>
      <Grid
        style={{ display: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        className="help-view"
      >
        <Grid>
          <h2>Precisa de ajuda?</h2>
          <p>Entre em contato com a gente pelo Alô Petz. </p>
        </Grid>
        <Grid>
          <Link
            target="_blank"
            underline="always"
            href="https://www.petz.com.br/atendimento-inteligente"
          >
            <Button variant="primary" className="btn">
              Fale com o Alô Petz
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HelpCard;

