import React from "react";
import { Grid, Container, Link } from "@material-ui/core";

const politicsInfo = [
  {
    title: "Nossas Políticas",
    text: "Poderão participar da Campanha Promocional os clientes (Pessoa Física), cadastrado na Petz, com idade igual ou superior a 18 anos. É necessário ser residente e domiciliado em território nacional.",
  },
  {
    title: "Validade da promoção",
    text: "A promoção é válida de 03 de junho de 2024 às 00h00 até 30 de novembro de 2024 às 23h59 (horário de Brasília), ou até que os números da sorte se esgotem, o que acontecer primeiro.",
  },
  {
    title: "Dúvidas e atendimento ",
    text: "Para outras dúvidas, consulte o regulamento completo da promoção. Mais informações no Alô Petz pelo telefone (11) 3434.6980 ou no Fale Conosco.",
  },
  {
    title: "Horário de atendimento: ",
    list: (
      <>
        <li>Segunda a sexta: 8h às 21h</li>
        <li>Sábados: 8h às 18h</li>
        <li>Domingos e feriados: 9h às 16h</li>
      </>
    ),
  },
];

const Politics = () => {
  return (
    <>
      <Container>
        <Grid className="politics-view">
          <Grid className="link">
            <Link href="/regulamento" underline="always">
              Regulamento
            </Link>
          </Grid>
          <Grid className="link">
            <Link
              target="_blank"
              underline="always"
              href="https://www.petz.com.br/institucional/politica-de-privacidade"
            >
              Política de Privacidade
            </Link>
          </Grid>
          <Grid style={{ display: "flex" }} className="politics-wrapper">
            {politicsInfo.map(({ title, text, list }) => {
              return (
                <Grid
                  style={{ display: "flex" }}
                  direction="column"
                  className="politics-content"
                >
                  <h2> {title}</h2>
                  {text ? <p>{text}</p> : <ul> {list} </ul>}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Politics;

