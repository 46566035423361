import React from "react";
import { Grid, Link, useMediaQuery } from "@material-ui/core";
import ContentSelos from "../../assets/i/contents-selos.png";
import FaceLogo from "../../assets/i/social-white/facebook.svg";
import InstaLogo from "../../assets/i/social-white/instagram.svg";
import LinkedinLogo from "../../assets/i/social-white/linkedin.svg";
import YoutubeLogo from "../../assets/i/social-white/youtube.svg";
import TiktokLogo from "../../assets/i/social-white/tiktok.svg";
import PinterestLogo from "../../assets/i/social-white/pinterest.svg";
import PaymentLogo from "../../assets/i/payments/logo.svg";
import PaymentLogo2 from "../../assets/i/payments/logo2.svg";
import PaymentLogo3 from "../../assets/i/payments/logo3.png";
import PaymentLogo4 from "../../assets/i/payments/logo4.svg";
import PaymentLogo5 from "../../assets/i/payments/logo5.png";
import PaymentLogo6 from "../../assets/i/payments/logo6.png";
import PaymentLogo7 from "../../assets/i/payments/logo7.png";
import PaymentLogo8 from "../../assets/i/payments/logo8.png";
import FaceLogoWeb from "../../assets/i/social/facebook-logo.svg";
import InstaLogoWeb from "../../assets/i/social/instagram-logo.svg";
import LinkedinLogoWeb from "../../assets/i/social/linkedin.svg";
import YoutubeLogoWeb from "../../assets/i/social/youtube.svg";
import TiktokLogoWeb from "../../assets/i/social/tiktok.svg";
import PinterestLogoWeb from "../../assets/i/social/pinterest.svg";
import Logo from "../../assets/i/logo.svg";

const socialMediaInfo = [
  {
    title: "Selos e certificados",
    img: (
      <img
        src={ContentSelos}
        alt="selos e certificados"
        style={{ width: "100%" }}
      />
    ),
  },
  {
    title: "Formas de pagamento",
    images: [
      <img src={PaymentLogo} alt="Mastercard" />,
      <img src={PaymentLogo2} alt="Visa" />,
      <img src={PaymentLogo3} alt="American Express" />,
      <img src={PaymentLogo4} alt="Código" />,
      <img src={PaymentLogo5} alt="Hipercard" />,
      <img src={PaymentLogo6} alt="Dinners Club" />,
      <img src={PaymentLogo7} alt="Código" />,
      <img src={PaymentLogo8} alt="Elo" />,
    ],
  },
  {
    title: "Siga a gente ",
    images: [
      <Link target="_blank" href="https://www.facebook.com/petzbr">
        <img src={FaceLogo} alt="Facebook" />
      </Link>,
      <Link target="_blank" href="https://www.instagram.com/petz/">
        <img src={InstaLogo} alt="Instagram" />
      </Link>,
      <Link target="_blank" href="https://www.linkedin.com/company/petz/">
        <img src={LinkedinLogo} alt="Linkedin" />
      </Link>,
      <Link target="_blank" href="https://www.youtube.com/petztube">
        <img src={YoutubeLogo} alt="Youtube" />
      </Link>,
      <Link target="_blank" href="https://www.tiktok.com/@petz">
        <img src={TiktokLogo} alt="Tiktok" />
      </Link>,
      <Link target="_blank" href="https://br.pinterest.com/somospetz/">
        <img src={PinterestLogo} alt="Pinterest" />
      </Link>,
    ],
    classContainer: "media-icons",
  },
  {
    title: "Siga a gente ",
    images: [
      <Link target="_blank" href="https://www.facebook.com/petzbr">
        <img src={FaceLogoWeb} alt="Facebook" />
      </Link>,
      <Link target="_blank" href="https://www.instagram.com/petz/">
        <img src={InstaLogoWeb} alt="Instagram" />
      </Link>,
      <Link target="_blank" href="https://www.linkedin.com/company/petz/">
        <img src={LinkedinLogoWeb} alt="Linkedin" />
      </Link>,
      <Link target="_blank" href="https://www.youtube.com/petztube">
        <img src={YoutubeLogoWeb} alt="Youtube" />
      </Link>,
      <Link target="_blank" href="https://www.tiktok.com/@petz">
        <img src={TiktokLogoWeb} alt="Tiktok" />
      </Link>,
      <Link target="_blank" href="https://br.pinterest.com/somospetz/">
        <img src={PinterestLogoWeb} alt="Pinterest" />
      </Link>,
    ],
    classItem: "media-item",
  },
  {
    title: "Plataforma: ",
    img: <img src={Logo} alt="plataforma" />,
    classContainer: "align-items-center",
  },
];

const SocialMedia = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const infoMobile = [
    Object.values(socialMediaInfo)[2],
    Object.values(socialMediaInfo)[1],
    Object.values(socialMediaInfo)[0],
    Object.values(socialMediaInfo)[4],
  ];
  const infoDesktop = [
    Object.values(socialMediaInfo)[0],
    Object.values(socialMediaInfo)[1],
    Object.values(socialMediaInfo)[3],
    Object.values(socialMediaInfo)[4],
  ];
  const listSocialMedia = isDesktop ? infoDesktop : infoMobile;

  return (
    <Grid className="social-media">
      {listSocialMedia?.map(
        ({ title, images, img, classContainer, classItem }) => {
          return (
            <Grid className={classContainer + " social-container"}>
              <h1 className="title">{title}</h1>
              <Grid className="group">
                {images ? (
                  images?.map((image) => {
                    return <Grid className={classItem + " item"}>{image}</Grid>;
                  })
                ) : (
                  <Grid>{img}</Grid>
                )}
              </Grid>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default SocialMedia;
