import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const Home = React.lazy(() => import("../views/home/Home"));
const Login = React.lazy(() => import("../views/login/Login"));
const Regulamento = React.lazy(() =>
  import("../views/regulamento/Regulamento")
);
const Winners = React.lazy(() =>
  import("../views/winners/Winners")
)
const Faq = React.lazy(() => import("../views/faq/Faq"));
const NumeroSorte = React.lazy(() =>
  import("../views/luckyNumber/LuckyNumber")
);

export const routes = [
  {
    id: 1,
    path: "home",
    title: "Home",
    element: <Home />,
  },
  { id: 2, path: "login", title: "Login", element: <Login /> },
  {
    id: 3,
    path: "regulamento",
    title: "Regulamento",
    element: <Regulamento />,
  },
  {
    id: 4,
    path: "ganhadores",
    title: "Ganhadores",
    element: <Winners />,
  },
  {
    id: 5,
    path: "perguntas-frequentes",
    title: "FAQs",
    element: <Faq />,
  },
  {
    id: 6,
    path: "numero-sorte",
    title: "Seus números da sorte",
    element: <NumeroSorte />,
  },
];

export const getTitle = (path) => routes.find((r) => r.path === path).title;

const AppRoutes = ({ premiosRef }) => {
  return (
    <Routes>
      <Route
        premiosRef={premiosRef}
        exact
        key={0}
        path="/"
        element={<Navigate to="/home" />}
      />
      {routes.map((r) => (
        <Route
          premiosRef={premiosRef}
          key={r.id}
          path={r.path}
          element={r.element}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
