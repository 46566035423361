import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container, Nav, Navbar } from "react-bootstrap";
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  Dialog,
  useMediaQuery,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Home from "@material-ui/icons/Home";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import PersonIcon from "@material-ui/icons/Person";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ReactComponent as PetzWhiteLogo } from "../../assets/i/logo.svg";
import { ReactComponent as Security } from "../../assets/i/security.svg";
import ModalGeneric from "../../components/modal/ModalGeneric";
import { UserContext } from "../../App";
import "./Header.scss";

const Header = () => {
  const navigate = useNavigate();
  const [showModalGeneric, setShowModalGeneric] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [toggle, setToggle] = useState(null);
  const [openSlide, setOpenSlide] = useState(false);
  const upUntilLargeScreen = useMediaQuery("(max-width: 1199px)");
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const htmlElement = document.body;
  const open = Boolean(toggle);
  const location = window.location.href;
  const login = location.substring(location.lastIndexOf("/"));

  const { loggedIn, setLoggedIn } = useContext(UserContext);
  const name = JSON.parse(localStorage.getItem("user_auth"))?.name.split(
    /\s(.+)/
  )[0];
  const optin = JSON.parse(localStorage.getItem("user_auth"))?.optin;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = () => {
    toggleMenu();
    navigate("/login");
    openSlide && setOpenSlide(false);
  };

  const handleClick = (event) => {
    setToggle(event.currentTarget);
  };

  const handleClose = () => {
    setToggle(null);
  };

  const logoutConfirm = () => {
    openSlide && setOpenSlide(false);
    setToggle(null);
    setShowModalGeneric(true);
    htmlElement.classList.add("dialog-close");
  };

  const logout = () => {
    setShowModalGeneric(false);
    localStorage.removeItem("user_auth");
    setLoggedIn();
    navigate("/home");
    window.scrollTo(0, 0);
  };

  const handleCloseGeneric = () => {
    setShowModalGeneric(false);
  };

  const handleClickOpenSlide = () => {
    setOpenSlide(true);
  };

  const handleCloseSlide = () => {
    setOpenSlide(false);
  };

  const iconHome = () => {
    return (
      <Nav.Link
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("/home");
          handleCloseSlide();
        }}
      >
        <PetzWhiteLogo />
      </Nav.Link>
    );
  };

  const loginArea = () => {
    return (
      <>
        {!loggedIn ? (
          <Grid className="d-flex justify-content-end">
            {login !== "/login" ? (
              <Grid className="button" onClick={handleLogin}>
                <span>Participar</span>
              </Grid>
            ) : (
              <Security />
            )}
          </Grid>
        ) : (
          <Grid className="d-flex justify-content-end">
            <Grid>
              <Button onClick={handleClick}>
                <h3>Olá, {name}!</h3>
              </Button>
              <Menu
                anchorEl={toggle}
                open={open}
                onClose={handleClose}
                style={{
                  marginTop: "50px",
                  borderRadius: "10px",
                  padding: "0 10px",
                }}
              >
                <MenuItem
                  style={{
                    color: "#c62015",
                    gap: "1rem",
                    padding: "15px 30px",
                  }}
                  onClick={logoutConfirm}
                >
                  <ExitToAppIcon />
                  Sair
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const menuNavigationArea = () => {
    return (
      <Grid className={isDesktop ? "menu-web" : "menu-mobile"}>
        <Grid
          className="d-flex gap-3"
          direction={upUntilLargeScreen && "column"}
        >
          {loggedIn && upUntilLargeScreen && (
            <Grid className="user-mobile">{loginArea()}</Grid>
          )}
          <Nav.Link
            className="nav"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/home");
              upUntilLargeScreen && handleCloseSlide();
            }}
          >
            {upUntilLargeScreen && <Home />} Página Inicial
          </Nav.Link>

          {loggedIn && optin && (
            <Nav.Link
              className="nav"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/numero-sorte");
                upUntilLargeScreen && handleCloseSlide();
              }}
            >
              {upUntilLargeScreen && <ConfirmationNumberIcon />} Números da
              sorte
            </Nav.Link>
          )}

          <Nav.Link
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/ganhadores");
              upUntilLargeScreen && handleCloseSlide();
            }}
            className="nav"
          >
            {upUntilLargeScreen && <PersonIcon />} Ganhadores
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/perguntas-frequentes");
              upUntilLargeScreen && handleCloseSlide();
            }}
            className="nav"
          >
            {upUntilLargeScreen && <HelpOutlineIcon />} Dúvidas frequentes
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/regulamento");
              upUntilLargeScreen && handleCloseSlide();
            }}
            className="nav"
          >
            {upUntilLargeScreen && <FormatListBulletedIcon />} Regulamento
          </Nav.Link>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className="menu-container">
      <Helmet title={`Petz | Promo PIX 2024`} />
      <Navbar collapseOnSelect key={false} expand={false}>
        <Container fluid>
          <Dialog
            open={openSlide}
            onClose={handleCloseSlide}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid style={{ display: "flex" }} alignItems="center">
              <span
                style={{
                  marginRight: "1rem",
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
                onClick={handleCloseSlide}
              >
                x
              </span>
              {iconHome()}
            </Grid>
            {menuNavigationArea()}
            {!loggedIn && <Grid className="buttons-mobile">{loginArea()}</Grid>}
          </Dialog>

          {upUntilLargeScreen && (
            <>
              <Button variant="outlined" onClick={handleClickOpenSlide}>
                <MenuIcon />
              </Button>
              {iconHome()}
            </>
          )}

          {isDesktop && (
            <>
              <Grid style={{ display: "flex" }} alignItems="center">
                {iconHome()}
                {menuNavigationArea()}
              </Grid>
              {loginArea()}
            </>
          )}

          <ModalGeneric
            showModal={showModalGeneric}
            handleClose={handleCloseGeneric}
            title="Sair"
            text="Tem certeza que deseja sair da sua conta?"
            buttonClick={handleCloseGeneric}
            buttonText="Cancelar"
            buttonType="secondary"
            linkClick={logout}
            linkText="Sim"
          />
        </Container>
      </Navbar>
    </Grid>
  );
};

export default Header;

